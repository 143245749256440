import AppLink from '@theme/components/atom/AppLink'
import SubCategoryBox from '@theme/components/category/SubCategoryBox'
import UtilityGridMixin from '~/mixins/UtilityGridMixin'

export default {
  components: {
    AppLink,
    SubCategoryBox,
  },
  mixins: [UtilityGridMixin],
  data() {
    return {
      dropdownGridClass: '',
    }
  },
  props: {
    items: {
      type: [Object, Array],
      default: () => [],
    },
    heading: {
      type: String,
      default() {
        return this.$t('MainMenuDropdownContent.heading')
      },
    },
  },
  mounted() {
    this.dropdownGridClass = this.computeColumnClass(
      this.$themeSettings.components.MainMenuDropdownContent.subcategoriesCountPerLine,
    )
  },
}
